<template>
  <div class="home">
    <div id="wrapper">
      <Sidebar :role="userInfo.role"/>
      <div id="content-wrapper" class="d-flex flex-column">
        <div id="content">
          <Navbar :name="userInfo.name"/>
          <div class="container-fluid">
            <div class="d-sm-flex align-items-center justify-content-between mb-4">
                <h1 class="h3 mb-0 text-gray-800">Edit Article</h1>
            </div>
            <div class="card shadow mb-4">
                <div class="card-header py-3">
                    <div class="card-body">
                        <div>
                            <div>
                                <div class="form-group">
                                    <label for="exampleInputTitle">Title</label>
                                    <input type="text" v-model="title" class="form-control" id="exampleInputTitle" aria-describedby="titleHelp">
                                </div>
                                <div class="form-group">
                                    <label for="exampleInputContent">Content</label>
                                    <input type="text" v-model="content" class="form-control" id="exampleInputContent" aria-describedby="contentHelp">
                                </div>
                            </div>
                            <br>
                            <div class="row" style="padding-bottom:50px;">
                                <button @click="editArticle()" class="btn btn-primary" type="button" data-dismiss="modal">Edit Article</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <p>Copyright &copy; 2022 Nanosense Instrument Indonesia. All rights reserved.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Sidebar from '@/components/Sidebar.vue'
import Navbar from '@/components/Navbar.vue'
import axios from 'axios'
import Swal from 'sweetalert2'


export default {
  name: 'Edit',
  components: {
    Navbar,
    Sidebar
  },
  props: ['url'],
  data: () => {
        return {
            userInfo: {},
            title: "",
            content: "",
        }
  },
  methods : {
      getUserInfo() {
            axios.get(`${this.url}/users/${localStorage.getItem('user_id')}`)
            .then((response) => {
                let userInfo = response.data.data
                this.userInfo = userInfo
                this.name = userInfo.name
                this.email = userInfo.email
            })
            .catch((error) => {
                console.log(error.message)
            })
        },
      getArticle() {
            let config = {
                method: 'GET',
                headers: {'auth-tok': localStorage.getItem('token_monitor')},
                baseURL: `${this.url}/articles/${this.$route.params.id}`,
            }
            axios(config)
                .then((response) =>{
                    this.title = response.data.data.title
                    this.content = response.data.data.content
                })
                .catch((error) => {
                    console.log(error.message)
                })
        },
      editArticle() {
            let obj = {
                title: this.title,
                content: this.content
            }

            let config = {
                method: 'PATCH',
                headers: {'auth-tok': localStorage.getItem('token_monitor')},
                baseURL: `${this.url}/articles/${this.$route.params.id}`,
                data: obj
                }
            axios(config)
                .then(() =>{
                    Swal.fire('Article edited Successfully', '', 'success')
                })
                .catch((error) => {
                    Swal.fire('Something went wrong!', '', 'error')
                    console.log(error.message)
                })
        }
  },
  mounted() {
      if (localStorage.getItem('reloaded')) {
          localStorage.removeItem('reloaded');
      }
      else {
          localStorage.setItem('reloaded', '1');
          location.reload();
      }
  },
  created() {
      if (!localStorage.getItem("token_monitor")) {
          this.$router.push('/')
      }
      else {
        this.getUserInfo()
        this.getArticle()
      }
  }
}
</script>
